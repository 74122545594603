"use client"

import { useLayoutEffect } from "react"
import Link from "next/link"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { CheckCircleIcon, ClipboardListIcon, CreditCardIcon, UserCircle2Icon } from "lucide-react"
import { useTranslations } from "next-intl"

import { cn } from "@/lib/utils"
import HeaderLogo from "@/components/storyblok/header/HeaderLogo"

interface UBCheckoutHeaderProps {
  blok: any
  isB2BCustomer: boolean
  sbLocale: string
}

interface CheckoutHeaderItem {
  icon: any
  name: string
}

interface UBCheckoutHeaderItemProps {
  blok: any
  icon: any
  name: string
  muted: boolean
}

function UBCheckoutHeaderItem({ blok, ...restProps }: UBCheckoutHeaderItemProps) {
  const t = useTranslations("storefront")
  return (
    <div className={"flex flex-row items-center justify-center gap-2"}>
      <div
        className={cn(
          "flex justify-center items-center rounded-full bg-primary border-primary border-2 size-6 sm:size-8 md:size-12",
          {
            "bg-white border-muted-foreground": restProps.muted,
          }
        )}
      >
        <restProps.icon
          className={cn("size-2 sm:size-4 md:size-8 stroke-1", {
            "text-muted-foreground": restProps.muted,
            "text-white": !restProps.muted,
          })}
        />
      </div>
      <div className={cn("hidden lg:block font-bold", { "text-muted-foreground": restProps.muted })}>
        {t("checkout.header.item." + restProps.name)}
      </div>
    </div>
  )
}

export default function UBCheckoutHeaderClient({ blok, isB2BCustomer, sbLocale, ...restProps }: UBCheckoutHeaderProps) {
  const { setIsB2BCustomer } = useUbStorefrontState()
  const items: CheckoutHeaderItem[] = [
    {
      icon: UserCircle2Icon,
      name: "addresses",
    },
    {
      icon: CreditCardIcon,
      name: "payment",
    },
    {
      icon: ClipboardListIcon,
      name: "review",
    },
    {
      icon: CheckCircleIcon,
      name: "confirmation",
    },
  ]

  let muted = false

  useLayoutEffect(() => {
    setIsB2BCustomer(isB2BCustomer)
  }, [setIsB2BCustomer, isB2BCustomer])

  return (
    /*Why w-screen? See:
     https://github.com/radix-ui/primitives/issues/1496 and
     https://www.reddit.com/r/reactjs/comments/1fjcwkh/comment/lnt3a0j/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button*/
    <div className={cn("z-[151] w-full bg-white", {})}>
      <div
        className={cn(
          "mx-auto flex min-h-14 max-w-7xl items-start border-b border-gray-200 px-4 py-2 sm:px-6 lg:h-[90px] lg:border-0 lg:px-8"
        )}
      >
        <div className="flex w-full flex-row items-center gap-4">
          <HeaderLogo logo={blok.logo} subtext={blok.subtext} sbLocale={sbLocale} />
          <div className={cn("flex grow flex-row items-center justify-end gap-4")}>
            {items.map((item) => {
              let ubCheckoutHeaderItem =
                !muted && item.name != blok.active && blok.active !== "confirmation" ? (
                  <Link
                    key={item.name}
                    prefetch={false}
                    className={cn("flex items-center gap-1")}
                    href={"/basket/checkout/" + item.name}
                  >
                    <UBCheckoutHeaderItem blok={blok} icon={item.icon} name={item.name} muted={muted} />
                  </Link>
                ) : (
                  <div key={item.name} className={cn("flex items-center gap-1")}>
                    <UBCheckoutHeaderItem
                      blok={blok}
                      icon={item.icon}
                      name={item.name}
                      muted={muted && blok.active !== "confirmation"}
                    />
                  </div>
                )
              if (item.name == blok.active) {
                muted = true
              }
              return ubCheckoutHeaderItem
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
