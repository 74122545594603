"use client"

import React, { PropsWithChildren, Suspense, useEffect } from "react"
import {
  loadBasketLineItemsAction,
  loadBasketPromotionCodesAction,
  loadCurrentBasketAction,
} from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { BasketContext } from "@/lib/storefront/basket/basket"
import { Skeleton } from "@/components/ui/components"
import BasketRecentlyViewedProducts from "@/components/basket/BasketRecentlyViewedProducts"
import { BasketLineItemList } from "@/components/basket/overview/BasketLineItemList"
import { UbBasketLineItemsStoryblok } from "@/components/storyblok/component-types"

interface BasketLineItemsProps extends PropsWithChildren {
  basketContext: BasketContext
  blok: UbBasketLineItemsStoryblok
}

export default function BasketLineItems(props: BasketLineItemsProps) {
  const isoLocale = props.basketContext.isoLocale
  const isB2B = props.basketContext.isB2B
  const userData = props.basketContext.userData

  const { basketData, basketLineItems, setBasketData, setBasketLineItems, setBasketPromotionCodes } =
    useUbStorefrontState()

  /*  useEffect(() => {
    console.dir(basketLineItems)
    console.dir(basketData)
  }, [basketData, basketLineItems])*/

  useEffect(() => {
    if (basketData) {
      loadBasketLineItemsAction(isoLocale, isB2B, userData, basketData).then((lineItems) => {
        if (lineItems) {
          lineItems.sort((a, b) => (a.position > b.position ? 1 : -1))
          //console.dir(lineItems, {depth: 9})

          setBasketLineItems(lineItems)
        }
      })
    } else {
      loadCurrentBasketAction().then((loadedBasketData) => {
        if (loadedBasketData) {
          setBasketData(loadedBasketData)
        }
      })
    }
  }, [basketData, isB2B, isoLocale, setBasketData, setBasketLineItems, userData])

  useEffect(() => {
    if (basketData) {
      loadBasketPromotionCodesAction().then((promotionCodes) => {
        if (promotionCodes) {
          setBasketPromotionCodes(promotionCodes)
        }
      })
    }
  }, [basketData, isB2B, setBasketPromotionCodes])

  return (
    <div className={"flex w-full flex-col gap-2"}>
      <Suspense>
        <>
          {basketData &&
          (basketData?.data?.totalProductQuantity ?? 0) > 0 &&
          basketLineItems &&
          basketLineItems.length > 0 ? (
            <>
              <div className={"grid w-full grid-cols-1 gap-y-2"}>
                <div className={"col-span-2"}>
                  <div className={"flex flex-col gap-2"}>
                    <BasketLineItemList isB2B={isB2B} basketLineItems={basketLineItems} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {!basketData && (
                <div className="flex flex-col gap-4">
                  <Skeleton className="h-40 w-full" />
                  <Skeleton className="h-40 w-full" />
                </div>
              )}

              <div>{basketData && (basketData?.data?.totalProductQuantity ?? 0) < 1 && <p>Warenkorb ist leer.</p>}</div>
            </>
          )}
        </>
      </Suspense>

      <div className={"w-full"}>
        <BasketRecentlyViewedProducts />
      </div>
    </div>
  )
}
