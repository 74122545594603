import { ProductPriceContainerCtnrROV1 } from "@ub/pricing-client"
import { useTranslations } from "next-intl"
import { ulid } from "ulid"

import { determineCardPrices } from "@/lib/price-utils"
import { ReducedProductData } from "@/lib/storefront/product/product"
import { showProductPrice } from "@/lib/storefront/product/productHelper"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/components"
import { calculateAvailabilityData } from "@/components/ui/helpers/product-availability-helper"
import ProductCardAddToBasket from "@/components/product/ProductCardAddToBasket"
import ProductCardDiscount from "@/components/product/ProductCardDiscount"
import ProductCardPrice from "@/components/product/ProductCardPrice"
import ProductClickTrackingClient from "@/components/product/ProductClickTrackingClient"
import ImageWithFallback from "@/components/storyblok/common/ImageWithFallback"

import { getResizedSbImagepath } from "../ui/helpers/resize-sb-image"

function determineDirectlyAddableArticleId(productData: ReducedProductData | undefined) {
  // if (productData?.mastered) {
  if (!productData?.id.toUpperCase().startsWith("E")) {
    return productData?.id ?? null
  } else {
    return null
  }
}

interface ProductCardProps {
  productData: ReducedProductData | undefined
  /*
  standardVariant: ReducedProductData | undefined
*/
  showDescription: boolean | undefined
  showEcoTaxFlag: boolean | undefined
  debugMode: boolean | undefined
  prices: ProductPriceContainerCtnrROV1 | undefined
  compact?: boolean
  hideAddToBasket: boolean
  isB2BCustomer: boolean
  trackingList?: string
  position?: number
  priceRequestLink: string
  isoLocale: string
  view: string
}

export default function ProductCard(props: ProductCardProps) {
  const t = useTranslations("storefront")
  //console.log(props.isoLocale)
  const view = props.view
  //If a product is mastered, then we assume that it could be sold directly otherwise the customer needs to choose a variant first on PDP
  // const hasVariants = !props.productData?.mastered

  const hasMulticolor = props.productData?.hasMultiColor

  const directlyAddableArticleId = determineDirectlyAddableArticleId(props.productData)
  const hasVariants = !directlyAddableArticleId

  const cardPrices = determineCardPrices(props.isoLocale, props.isB2BCustomer, props.prices)
  const { showPrice, needsPriceQuotation } = showProductPrice(props.productData, cardPrices)

  //UBW-650 - Always link to PDP first
  const cardButtonHref = props.productData?.link
  //const cardButtonHref = showPrice ? props.productData?.link : props.priceRequestLink + props.productData?.id

  const trackingMarker = ulid()

  return (
    <>
      {props.productData ? (
        <a data-tracking-marker={trackingMarker} className="size-full @container/productcard" href={cardButtonHref}>
          <ProductClickTrackingClient
            trackingMarker={trackingMarker}
            productData={props.productData}
            trackingList={props.trackingList}
            position={props.position ?? 1}
          />

          <ProductCardDiscount
            isoLocale={props.isoLocale}
            product={props.productData}
            prices={props.prices}
            isB2BCustomer={props.isB2BCustomer}
            legacyPrices={true}
          />

          {/*inner main div*/}
          <div
            className={cn("flex h-full flex-1 grow flex-col justify-between gap-2 p-2", {
              "p-2": props.compact,
              "sm:flex-row": view === "list",
            })}
          >
            <div
              className={cn("group flex flex-col gap-2", {
                "basis-32 flex-none": view === "list",
              })}
            >
              {/*top / left half*/}
              <div className="flex flex-col items-center justify-center gap-2">
                {/*image*/}
                <div
                  className={cn("flex max-h-[270px] items-center", {
                    "max-h-[270px]": props.compact,
                  })}
                >
                  {props.productData.image ? (
                    <ImageWithFallback
                      unoptimized={true}
                      className={cn(
                        "aspect-square max-h-[270px] overflow-hidden object-contain transition duration-200 ease-in-out group-hover:scale-105",
                        {
                          "max-h-[270px]": props.compact,
                        }
                      )}
                      alt={props.productData.title}
                      src={getResizedSbImagepath(props.productData.image)}
                      width={props.productData.image_width}
                      height={props.productData.image_height}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {/*bottom / right half*/}
            <div className="flex min-h-24 flex-col grow justify-end gap-2 md:min-h-24 lg:min-h-28">
              {/*title*/}
              <div
                className={cn("h-8 min-h-8 w-full text-left", {
                  "h-auto": props.debugMode,
                })}
              >
                <p
                  className={cn(
                    "line-clamp-2 overflow-hidden text-ellipsis text-sm font-bold !leading-tight text-gray-900 sm:text-base",
                    {
                      "text-base": props.compact,
                      "h-auto max-h-auto line-clamp-none": props.debugMode,
                    }
                  )}
                >
                  {props.productData.title}
                </p>
              </div>
              {/*description*/}
              {props.showDescription && (
                <p
                  className={cn("line-clamp-1 h-5 max-h-5 overflow-hidden text-ellipsis text-sm", {
                    "h-auto max-h-auto line-clamp-none	": props.debugMode,
                  })}
                  dangerouslySetInnerHTML={{
                    __html: props.productData.description ? props.productData.description : "",
                  }}
                />
              )}
              {/*prices and buttons*/}
              <div className={"flex h-full flex-col justify-between gap-4"}>
                <div className={"min-h-22 flex flex-row gap-1"}>
                  <div className={"flex min-h-20 w-full grow flex-col justify-end gap-1"}>
                    {hasMulticolor ? (
                      <div className="flex items-center justify-start gap-2">
                        <ImageWithFallback
                          unoptimized={true}
                          width={32}
                          height={32}
                          className={"aspect-square h-8 lg:h-6 xl:h-8"}
                          alt={""}
                          src={"/img/multicolor.svg"}
                        />
                        {!props.compact && (
                          <p className={"text-xs leading-tight lg:text-[0.65em] xl:text-xs"}>
                            {t("product.multiColor")}
                          </p>
                        )}
                      </div>
                    ) : (
                      <p></p>
                    )}

                    {showPrice && (
                      <ProductCardDeliveryInfo isoLocale={props.isoLocale} productData={props.productData} />
                    )}
                  </div>

                  <ProductCardPrice
                    isoLocale={props.isoLocale}
                    showEcoTaxFlag={props.showEcoTaxFlag}
                    productData={props.productData}
                    cardPrices={cardPrices}
                    isB2BCustomer={props.isB2BCustomer}
                    showPrice={showPrice}
                  />

                  {/*truck and multicolor*/}
                </div>
                {/*buttons*/}
                <div className="flex flex-row items-end justify-end">
                  {showPrice && hasVariants && (
                    <Button size={"productCard"} className="w-full" variant={"default"}>
                      {t("product.go_to_variants")}
                    </Button>
                  )}

                  {showPrice && !hasVariants && (
                    <div className={"flex w-full justify-between gap-x-2"}>
                      <div
                        className={cn("w-full md:w-3/4", {
                          "md:w-full": !directlyAddableArticleId || props.hideAddToBasket,
                        })}
                      >
                        <Button size={"productCard"} className="w-full" variant={"default"}>
                          {t("product.go_to_product")}
                        </Button>
                      </div>
                      <div
                        className={cn("block md:block", {
                          "md:hidden w-0 md:w-0": !directlyAddableArticleId || props.hideAddToBasket,
                        })}
                      >
                        {directlyAddableArticleId && !props.hideAddToBasket && (
                          <ProductCardAddToBasket
                            articleIdToAdd={directlyAddableArticleId}
                            productData={props.productData}
                            prices={props.prices}
                            quantity={props.productData?.stepOrderQuantity ?? 1}
                          />
                        )}
                      </div>
                    </div>
                  )}

                  {!showPrice && (
                    <Button size={"productCard"} className="w-full" variant={"default"}>
                      {t("product.request_price")}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </a>
      ) : (
        <div className="flex size-full items-center justify-center p-5">
          <img alt="Udobär Logo" src="/img/logo-de.png" />
        </div>
      )}
    </>
  )
}

function ProductCardDeliveryInfo(props: { productData: ReducedProductData | undefined; isoLocale: string }) {
  const t = useTranslations("storefront")

  const availabilityData = calculateAvailabilityData(props.isoLocale, props.productData, /*props.standardVariant,*/ t)
  const availabilityText = availabilityData.availabilityText ? "(" + availabilityData.availabilityText + ")" : ""

  return (
    <div className="text-wrap-balance flex items-center justify-start gap-2">
      <ImageWithFallback
        unoptimized={true}
        width={32}
        height={32}
        className={"aspect-square h-8 lg:h-6 xl:h-8"}
        alt={""}
        src={"/img/delivery.svg"}
      />
      <div className={"flex flex-col items-start justify-center"}>
        {props.productData?.availability === true ? (
          <>
            <p className={"text-xs leading-tight lg:text-[0.65em] xl:text-xs"}>
              {availabilityData.immediatelyAvailable ? t("product.available") : t("product.free_shipping")}
            </p>
            <p className={"text-xs leading-tight lg:text-[0.65em] xl:text-xs"}>{availabilityText}</p>
          </>
        ) : (
          <p className={"text-xs leading-tight lg:text-[0.65em] xl:text-xs"}>{t("product.delivery_time_unknown")}</p>
        )}
      </div>
    </div>
  )
}
