"use client"

import React, { useEffect, useState } from "react"
import { useRouter } from "next/navigation"
import {
  addOpenTenderPaymentAction,
  addPaymentInstrumentPaymentAction,
  loadBasketPaymentMethodsAction,
  removePaymentInstrumentAction,
} from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { BasketCtnrROV1IncludedPaymentsPaymentInstrument, PaymentMethodROV1 } from "@ub/basket-client"
import { useTranslations } from "next-intl"

import { CheckoutContext } from "@/lib/storefront/checkout/checkout-service"
import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger, Button } from "@/components/ui/components"
import PayoneCreditCardHostedIFrames from "@/components/checkout/payment/payone/PayoneCreditCardHostedIFrames"
import PayoneEpsBankGroupSelectBox from "@/components/checkout/payment/payone/PayoneEpsBankGroupSelectBox"
import BasicCard from "@/components/common/BasicCard"

const PAYONE_CREDITCARD_SERVICE_ID = "Payone_CreditCard"
const PAYONE_EPS_SERVICE_ID = "Payone_Eps"

interface CheckoutPaymentProps {
  checkoutContext: CheckoutContext
}

export default function CheckoutPaymentClient(props: CheckoutPaymentProps) {
  const t = useTranslations("storefront")

  const router = useRouter()
  const { basketData } = useUbStorefrontState()
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodROV1[]>([])
  const [paymentInstruments, setPaymentInstruments] = useState<BasketCtnrROV1IncludedPaymentsPaymentInstrument>({})
  const [paymentMethodIds, setPaymentMethodIds] = useState<string[]>([])
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const isB2B = props.checkoutContext.isB2B

  const [openItems, setOpenItems] = useState<string[]>([])

  const handleValueChange = (value: string[]) => {
    setOpenItems(value)
  }

  useEffect(() => {
    loadBasketPaymentMethodsAction(isB2B).then(async (paymentMethods) => {
      if (paymentMethods) {
        const pmd = paymentMethods.data ?? []
        setPaymentMethods(pmd)
        setPaymentInstruments(paymentMethods.included?.paymentInstruments ?? {})
        setPaymentMethodIds(
          pmd.map((pm: PaymentMethodROV1) => {
            return pm?.id ?? ""
          })
        )
      }
    })
  }, [basketData, isB2B])

  function removePaymentInstrument(paymentInstrument: string | undefined) {
    if (paymentInstrument) {
      removePaymentInstrumentAction(paymentInstrument, isB2B).then((value) => {
        if (value.result === "NOK") {
          setErrorMessage(value.message)
        } else {
          const copyOfPaymentInstruments = { ...paymentInstruments }
          delete copyOfPaymentInstruments[paymentInstrument]
          setPaymentInstruments((paymentInstrument) => ({ ...copyOfPaymentInstruments }))
        }
      })
    }
  }

  function addPayment(paymentInstrument: string | undefined) {
    if (paymentInstrument) {
      addOpenTenderPaymentAction(paymentInstrument, isB2B).then((value) => {
        if (value.result === "NOK") {
          setErrorMessage(value.message)
        } else {
          router.push("/basket/checkout/review")
        }
      })
    }
  }

  function addPaymentAction(formData: FormData) {
    const paymentMethodID = formData.get("paymentMethodID") as unknown as string
    const paymentMethodServiceID = paymentMethods?.find(
      (paymentMethod) => paymentMethod.id === paymentMethodID
    )?.serviceID
    let parameters = {}
    switch (paymentMethodServiceID) {
      case PAYONE_CREDITCARD_SERVICE_ID: {
        const pseudocardpan = formData.get("pseudocardpan") as object
        const truncatedcardpan = formData.get("truncatedcardpan") as object
        if (pseudocardpan && truncatedcardpan) {
          parameters = {
            pseudocardpan: pseudocardpan,
            truncatedcardpan: truncatedcardpan,
          }
        }
        break
      }
      case PAYONE_EPS_SERVICE_ID: {
        const bankGroupCode = formData.get("bankGroupCode") as object
        if (bankGroupCode) {
          parameters = {
            bankGroupCode: bankGroupCode,
          }
        }
        break
      }
      default:
        break
    }
    addPaymentInstrumentPaymentAction(paymentMethodID, parameters, isB2B).then((value) => {
      if (value.result === "NOK") {
        setErrorMessage(value.message)
      } else {
        router.push("/basket/checkout/review")
      }
    })
  }

  const getIncludedPaymentInstrumentIds = (paymentMethod: PaymentMethodROV1) => {
    return paymentMethod.paymentInstruments?.filter((paymentInstrument) => paymentInstruments[paymentInstrument]) ?? []
  }

  const getPayoneEpsElement = (paymentMethod: PaymentMethodROV1) => {
    const includedPaymentInstrumentIds = getIncludedPaymentInstrumentIds(paymentMethod)
    return includedPaymentInstrumentIds.length ? (
      <>
        {includedPaymentInstrumentIds.map((paymentInstrumentId, index) => (
          <div key={"instrument-" + index}>
            <div className={"flex flex-row items-center gap-2"}>
              <div>{paymentInstruments[paymentInstrumentId].accountIdentifier}</div>
              <Button
                size={"defaultNoGrow"}
                variant={"default"}
                className={"cursor-pointer bg-primary py-0 font-normal text-white"}
                type={"button"}
                onClick={() => removePaymentInstrument(paymentInstrumentId)}
              >
                {t("checkout.payment.instrument.remove")}
              </Button>
            </div>
            <div className={"flex justify-end"}>
              <Button
                size={"defaultNoGrow"}
                variant={"default"}
                className={"cursor-pointer bg-primary py-0 font-normal text-white"}
                type={"button"}
                onClick={() => addPayment(paymentInstrumentId)}
              >
                {t("checkout.payment.button")}
              </Button>
            </div>
          </div>
        ))}
      </>
    ) : (
      <>
        <div className={"flex flex-row items-center gap-2"}>
          <h2 className={"text-large font-bold"}>{t("checkout.payment.payone.eps.bankGroup")}</h2>
          <PayoneEpsBankGroupSelectBox paymentMethod={paymentMethod} isoLocale={props.checkoutContext.isoLocale} />
        </div>
        <div className={"flex justify-end"}>
          <Button
            size={"defaultNoGrow"}
            variant={"default"}
            className={"cursor-pointer bg-primary py-0 font-normal text-white"}
            type={"button"}
            onClick={() => {
              const paymentForm = document.querySelector<HTMLFormElement>('form[name="PaymentForm"]')
              const paymentMethodInput = document.querySelector<HTMLInputElement>('input[name="paymentMethodID"]')
              if (paymentForm && paymentMethodInput) {
                paymentMethodInput.value = paymentMethod.id as string
                paymentForm.requestSubmit()
              }
            }}
          >
            {t("checkout.payment.button")}
          </Button>
        </div>
      </>
    )
  }

  const getPayoneCreditCardElement = (paymentMethod: PaymentMethodROV1) => {
    return (
      <>
        <PayoneCreditCardHostedIFrames paymentMethod={paymentMethod} />
        {getIncludedPaymentInstrumentIds(paymentMethod).map((paymentInstrumentId, index) => (
          <div key={"instrument-" + index}>
            <hr className={"m-4"} />
            <div className={"flex flex-col gap-2"}>
              <h2 className={"text-large font-bold"}>{t("checkout.payment.payone.creditcard.cardnumber")}</h2>
              <div className={"flex flex-row items-center gap-2"}>
                <div>{paymentInstruments[paymentInstrumentId].accountIdentifier}</div>
                <Button
                  size={"defaultNoGrow"}
                  variant={"default"}
                  className={"cursor-pointer bg-primary py-0 font-normal text-white"}
                  type={"button"}
                  onClick={() => removePaymentInstrument(paymentInstrumentId)}
                >
                  {t("checkout.payment.instrument.remove")}
                </Button>
              </div>
              <div className={"flex justify-end"}>
                <Button
                  size={"defaultNoGrow"}
                  variant={"default"}
                  className={"cursor-pointer bg-primary py-0 font-normal text-white"}
                  type={"button"}
                  onClick={() => addPayment(paymentInstrumentId)}
                >
                  {t("checkout.payment.button")}
                </Button>
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <div className={"flex w-full flex-row"}>
      <div className={"flex w-full flex-col gap-2"}>
        <h2 className={"text-lg font-bold"}>{t("checkout.payment.cta")}</h2>
        {errorMessage && <p className={"text-xs text-red-500"}>{errorMessage}</p>}
        {paymentMethodIds && (
          <form name={"PaymentForm"} action={addPaymentAction}>
            <input type={"hidden"} name={"paymentMethodID"} id={"paymentMethodID"} />
            <AccordionRoot
              type={"multiple"}
              className={"w-full"}
              defaultValue={paymentMethodIds}
              value={paymentMethodIds}
              onValueChange={handleValueChange}
            >
              {paymentMethods.map((paymentMethod) => (
                <AccordionItem className={"pb-4"} key={paymentMethod.id} value={paymentMethod.id ?? ""}>
                  <AccordionTrigger className={"text-lg font-bold"}>{paymentMethod.displayName}</AccordionTrigger>
                  <AccordionContent>
                    <BasicCard className={"min-h-32"}>
                      {paymentMethod.serviceID === PAYONE_CREDITCARD_SERVICE_ID ? (
                        getPayoneCreditCardElement(paymentMethod)
                      ) : (
                        <div className={"flex h-full min-h-24 flex-col justify-between gap-2"}>
                          <div>{paymentMethod.description}</div>
                          {paymentMethod.serviceID === PAYONE_EPS_SERVICE_ID ? (
                            getPayoneEpsElement(paymentMethod)
                          ) : (
                            <div className={"flex justify-end"}>
                              <Button
                                size={"defaultNoGrow"}
                                variant={"default"}
                                className={"cursor-pointer bg-primary py-0 font-normal text-white"}
                                type={"button"}
                                onClick={() => addPayment(paymentMethod.paymentInstruments?.[0])}
                              >
                                {t("checkout.payment.button")}
                              </Button>
                            </div>
                          )}
                        </div>
                      )}
                    </BasicCard>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </AccordionRoot>
          </form>
        )}
      </div>
    </div>
  )
}
